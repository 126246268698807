/* HTML: <div class="loader"></div> */
.ReadMeLoader {
    width: 32px;
    aspect-ratio: 1;
    --c: no-repeat linear-gradient(#159d49 0 0);
    background:
            var(--c) 0%   50%,
            var(--c) 50%  50%,
            var(--c) 100% 50%;
    background-size: 20% 100%;
    animation: loader 1s infinite linear;
}
@keyframes loader {
    0%  {background-size: 20% 100%,20% 100%,20% 100%}
    33% {background-size: 20% 10% ,20% 100%,20% 100%}
    50% {background-size: 20% 100%,20% 10% ,20% 100%}
    66% {background-size: 20% 100%,20% 100%,20% 10% }
    100%{background-size: 20% 100%,20% 100%,20% 100%}
}
