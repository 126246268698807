.ReadmeButton {
  background: #fff;
  border: 1px solid #159d49;
  border-radius: 4px;
  color: #159d49;
  display: inline-block;
  font-size: 15px;
  line-height: 20px;
  padding: 5px 10px;
  text-align: center;

  &:hover {
    cursor: pointer;
    background-color: rgba(21, 157, 73, .05);
  }
}
